<template>
  <v-container fluid>
    <v-row class="justify-space-between align-center">
      <v-col>
        <v-chip
            small
            color="success"
            outlined
            v-show="status.moveStartDate"
        >
          <v-icon left>
            mdi-clock
          </v-icon>
          Simulation du {{ status.moveStartDate | startDateNow }} à {{ status.moveStartDate | startTimeNow }}
        </v-chip>


      </v-col>
      <v-col class="justify-space-between text-right">
        <v-dialog
            v-model="dialogMessage"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="orange lighten-1"
                small
                v-bind="attrs"
                v-on="on"
                class="white--text"
            >
              <v-icon class="pr-2" small> fas fa-envelope</v-icon>
              <span v-if="message.length !== 0">Modifier une annonce</span>
              <span v-else>Envoyer une annonce</span>
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="headline">
              <v-row>
                <v-col cols="10">
                  <span v-if="message.length !== 0">Editer une annonce</span>
                  <span v-else>Annonce à envoyer</span>
                </v-col>

                <v-col cols="2" class="text-right">
                  <v-btn icon @click="dialogMessage = false">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            </v-card-title>
            <v-card-text>
              <v-textarea
                  class="mt-8"
                  name="input-7-4"
                  outlined
                  v-model="message"
              ></v-textarea>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="error"
                  id="trash-btn"
                  @click="sendMessage('')"
              >
                Supprimer
              </v-btn>
              <v-btn
                  @click="sendMessage(message)"
                  color="primary"
                  text
              >
                Envoyer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
    <SimulatedDate :actual-move="actualMove" :status="status"/>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="moves"
            :items-per-page="-1"
            class="elevation-1"
            :item-class="selectedMoveClass"
        >
          <template v-slot:item.name="{ item }">
            <span class="mr-12" :class="item.selected === true ? 'indigo--text font-weight-bold selected-btn' : ''">
              {{ item.name }}
            </span>
          </template>

          <template v-slot:item.durationInMinutes="{ item }">


            <input :class="item.disabled === true ? '' : 'isEdited'"
                   :disabled="item.disabled"
                   v-model="item.durationInMinutes"
                   class="duration"
                   type="number">
          </template>

          <template v-slot:item.start="{ item }">
            <span class="text-capitalize" v-if="item.disabled">{{ item.start | moveDate }}
            </span>
            <v-row

                v-if="!item.disabled"
                justify="space-around"
                align="center"
            >
              <v-col>
                <h3>jour</h3>
                <v-date-picker v-if="!item.disabled" v-model="sdate"
                               @change="updateItemDate(item,sdate)"></v-date-picker>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.end="{ item }">
            <span class="text-capitalize" v-if="item.disabled">
              {{ item.start|moveStartToEnd(item.end) }}
            </span>

            <v-row

                v-if="!item.disabled"
                justify="space-around"
                align="center"
            >
              <v-col>
                <h3>début</h3>
                <v-time-picker
                    format="24hr"
                    v-model="stimes[0]"
                    :max="stimes[1]"
                    @click:hour="updateItemTimes(item,stimes)"
                    @click:minute="updateItemTimes(item,stimes)"
                ></v-time-picker>
              </v-col>
              <v-col>
                <h3>fin</h3>
                <v-time-picker
                    format="24hr"
                    v-model="stimes[1]"
                    :min="stimes[0]"
                    @click:hour="updateItemTimes(item,stimes)"
                    @click:minute="updateItemTimes(item,stimes)"
                ></v-time-picker>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="item.action === false">
              <v-btn icon>
                <v-icon
                    title="Amorcer"
                    @click="nextMove(item)" small>
                  fas fa-step-backward
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                    title="Démarrer"
                    @click="startMove(item)" small>
                  fas fa-play
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn class="stop-btn" icon>
                <v-icon
                    title="Terminer le move"
                    @click="endMove()" small>
                  fas fa-fast-forward
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon v-if='status.moveStatus === "STARTED"' @click="pauseMove(item)" small color="red accent-3">
                  fas fa-pause
                </v-icon>

                <v-icon
                    v-else
                    title="Démarrer"
                    @click="startMove(item)" small>
                  fas fa-play
                </v-icon>

              </v-btn>
            </div>
          </template>

          <template v-slot:item.edit="{ item }">
            <v-icon
                title="Modifier"
                v-if="(!item.selected || status.moveStatus === 'REALTIME') && item.disabled"
                @click="editItem(item)"
                class="mr-2"
                :disabled='item.selected && status.moveStatus !== "REALTIME"'
            >
              mdi-pencil
            </v-icon>

            <v-icon
                title="Sauvegarder"
                @click="saveItem(item)"
                class="mr-2"
                v-if="!item.disabled"
                color="green accent-4"
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table>

      </v-col>
    </v-row>
    <v-row>
      <v-col gutter="8px">
        <v-btn
            color="error"
            @click="resetVirtualDay"
            title="Arrêter le virtualday en cours / retour au temps réel"
        >
          <v-icon small left>
            fas fa-power-off
          </v-icon>
          RAZ


        </v-btn>
        <v-btn
            color="warning"
            @click="resetToDefaultConfiguration"
            title="Supprimer les modifications et restaurer la liste de virtualdays par défaut"
            class="mx-2" v-if="!isDefaultConfiguration">
          <v-icon small left>
            fas fa-redo-alt
          </v-icon>
          Config. par défaut
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {DateTime} from 'luxon';
import axios from 'axios';
import SimulatedDate from "./SimulatedDate";

const VIRTUAL_DAYS_LS_ITEM_NAME = "virtualdays";

Vue.filter("startTimeNow", (time) => DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE));
Vue.filter("moveDate", (time) => DateTime.fromISO(time).toLocaleString(DateTime.DATE_HUGE));
Vue.filter("moveStartToEnd", (start, end) => DateTime.fromISO(start).toLocaleString(DateTime.TIME_SIMPLE) + "\u00A0\u2794\u00A0" + DateTime.fromISO(end).toLocaleString(DateTime.TIME_SIMPLE))
Vue.filter("startDateNow", (time) => DateTime.fromISO(time).toLocaleString(DateTime.DATE_HUGE));

const warmupDays = [
  {
    name: "Virtual Day A",
    start: "2021-06-23T08:00:00",
    end: "2021-06-23T20:00:00",
    durationInMinutes: 50,
    action: false,
    disabled: true,
    selected: false
  },

  {
    name: "Virtual Day B",
    start: "2021-06-24T08:00:00",
    end: "2021-06-24T20:00:00",
    durationInMinutes: 50,
    action: false,
    disabled: true,
    selected: false
  },

  {
    name: "Virtual Day C",
    start: "2021-06-25T08:00:00",
    end: "2021-06-25T20:00:00",
    durationInMinutes: 50,
    action: false,
    disabled: true,
    selected: false
  },

  {
    name: "Virtual Day D",
    start: "2021-06-26T08:00:00",
    end: "2021-06-26T20:00:00",
    durationInMinutes: 50,
    action: false,
    disabled: true,
    selected: false
  },
]
const moveDays = [
      {
        name: "Virtual Day",
        start: "2021-06-27T08:00:00",
        end: "2021-06-27T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-06-28T08:00:00",
        end: "2021-06-28T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-06-29T08:00:00",
        end: "2021-06-29T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-06-30T08:00:00",
        end: "2021-06-30T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-01T08:00:00",
        end: "2021-07-01T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-02T08:00:00",
        end: "2021-07-02T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-03T08:00:00",
        end: "2021-07-03T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-04T08:00:00",
        end: "2021-07-04T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-05T08:00:00",
        end: "2021-07-05T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-06T08:00:00",
        end: "2021-07-06T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-07T08:00:00",
        end: "2021-07-07T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-08T08:00:00",
        end: "2021-07-08T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-09T08:00:00",
        end: "2021-07-09T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-10T08:00:00",
        end: "2021-07-10T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-11T08:00:00",
        end: "2021-07-11T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-12T08:00:00",
        end: "2021-07-12T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-13T08:00:00",
        end: "2021-07-13T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-14T08:00:00",
        end: "2021-07-14T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-15T08:00:00",
        end: "2021-07-15T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-16T08:00:00",
        end: "2021-07-16T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-17T08:00:00",
        end: "2021-07-17T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-18T08:00:00",
        end: "2021-07-18T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-19T08:00:00",
        end: "2021-07-19T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
      {
        name: "Virtual Day",
        start: "2021-07-20T08:00:00",
        end: "2021-07-20T20:00:00",
        durationInMinutes: 50,
        action: false,
        disabled: true,
        selected: false
      },
    ]
        .map((m, i) => Object.assign(m, {name: `${m.name} ${i + 1}`}))
;

const virtualdays = [...warmupDays, ...moveDays];

const urlApi = "/api/v1/";
const unselectMove = (m) => Object.assign({}, m, {disabled: true, selected: false, action: false});

export default {
  name: 'dateTime',
  components: {SimulatedDate},
  data() {
    return {
      headers: [
        {
          text: 'Virtual Day',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: 'Date', sortable: false, value: 'start'},
        {text: 'Début\u00A0\u2794\u00A0Fin', sortable: false, value: 'end'},
        {text: 'Durée (mn)', sortable: false, value: 'durationInMinutes'},
        {text: 'Démarrer', sortable: false, value: 'actions'},
        {text: 'Editer', sortable: false, value: 'edit'},
      ],
      moves: [],
      isDefaultConfiguration: false,
      now: 0,
      actualMove: {
        name: "",
        start: "",
        durationInMinutes: 0,
        duration: 0,
        end: ""
      },
      status: {
        startDate: "",
        pauseDate: "",
        moveStatus: "",
        statusDate: ""
      },
      dialogMessage: false,
      dialog: false,
      startTimer: null,
      selectedMoveStart: 0,
      selectedMove: {
        durationInMinutes: null,
        name: null,
        start: null
      },
      simulatedTimeStart: 0,
      displayProgressBar: false,
      timer: null,
      message: "",
      sdate: null,
      stimes: [],
    }
  },
  created() {
    this.connect();
  },
  mounted() {
    if (localStorage.getItem(VIRTUAL_DAYS_LS_ITEM_NAME)) {
      this.isDefaultConfiguration = false;
      this.moves = JSON.parse(localStorage.getItem(VIRTUAL_DAYS_LS_ITEM_NAME)).map(unselectMove);
    } else {
      this.moves = virtualdays;
      this.isDefaultConfiguration = true;
    }
  },
  methods: {
    selectedMoveClass(item) {
      return item.selected ? "selectedMove" : "";
    },
    updateItemDate(item, date) {
      item.start = `${date}T${item.start.split('T')[1]}`
      item.end = `${date}T${item.end.split('T')[1]}`
    },
    updateItemTimes(item, times) {
      item.start = `${item.start.split('T')[0]}T${times[0]}:00`
      item.end = `${item.end.split('T')[0]}T${times[1]}:00`
    },
    checkMove() {
      const selectedMove = this.moves.find((e) => e.name === this.actualMove.name);
      if (selectedMove) {
        selectedMove.action = true;
        selectedMove.selected = true;
        selectedMove.diabled = true;
        this.cleanMoves(this.actualMove);
      }
    },
    cleanMoves(activeMove) {
      this.moves.forEach((move) => {
        if (activeMove === null || activeMove.name !== move.name) {
          move.action = false;
          move.selected = false;
        }
      });
    },
    connect() {
      const es = new EventSource(urlApi + "flux");

      es.addEventListener('initMove', event => {
        this.actualMove = JSON.parse(event.data);
        this.checkMove();
      }, false);
      es.addEventListener('initStatus', event => {
        this.status = JSON.parse(event.data);
        this.checkMove();
      }, false);
      es.addEventListener('initMessage', event => {
        this.message = event.data;
      }, false);
      es.addEventListener('newMove', event => {
        this.actualMove = JSON.parse(event.data);
        this.checkMove();
      }, false);
      es.addEventListener('newMessage', event => {
        this.message = event.data;
      }, false)
      es.addEventListener('changeStatus', event => {
        this.status = JSON.parse(event.data);
        this.checkMove();
      }, false);
      es.addEventListener('error', event => {
        console.log("error","es state",es.readyState,"evt.data",event?.data);
        if (es.readyState > 0) {
          window.setTimeout(this.connect,2000);
        }
      }, false)
    },
    startMove(move) {
      this.cleanMoves(move);
      move.action = true;
      move.selected = true;
      this.selectedMove = move;
      axios
          .post(urlApi + "actualMove", move)
          .then(response => console.log(response.data))
          .catch(error => console.log(error))

    },
    pauseMove(move) {
      move.action = false;
      axios
          .post(urlApi + "pause")
          .then(response => console.log(response.data))
          .catch(error => console.log(error))
    },
    nextMove(move) {
      this.cleanMoves(move);
      move.action = true;
      move.selected = true;
      this.selectedMove = move;
      axios
          .post(urlApi + "next", move)
          .then(response => console.log(response.data))
          .catch(error => console.log(error))
    },
    resetMove() {
      axios
          .post(urlApi + "reset")
          .then(response => {
            console.log(response.data)
          })
          .catch(error => console.log(error))
    },
    endMove() {
      axios
          .post(urlApi + "end")
          .then(response => console.log(response.data))
          .catch(error => console.log(error))
    },
    sendMessage(m) {
      const message = {message: m};
      axios
          .post(urlApi + "actualMessage", message)
          .then(response => {
            console.log(response.data);
            this.dialogMessage = false;
          })
          .catch(error => console.log(error))
    },
    editItem(item) {
      this.moves.forEach(m => m.disabled = true)
      item.disabled = false;
      this.sdate = item.start.split('T')[0]
      this.stimes = [item.start, item.end].map(timeDate => timeDate.split('T')[1].split(":").slice(0, 2).join(":"))
    },
    saveItem(item) {

      item.disabled = true;
      const parsed = JSON.stringify(this.moves.map(unselectMove));
      localStorage.setItem(VIRTUAL_DAYS_LS_ITEM_NAME, parsed);
      this.isDefaultConfiguration = false;
    },
    resetToDefaultConfiguration() {
      localStorage.removeItem(VIRTUAL_DAYS_LS_ITEM_NAME)
      this.moves = virtualdays;
      this.isDefaultConfiguration = true;
    },
    resetVirtualDay() {
      this.actualMove = {};
      this.resetMove()
      this.cleanMoves(this.actualMove)
    }
  },

}
</script>
<style lang="scss">
.isEdited {
  border: 1px solid red;
  box-sizing: border-box;
  padding: 5px;
}

.stop-btn:hover {
  color: #0053C8 !important;
}

.selected-btn {
  box-sizing: border-box;
  padding: 4px;
  margin: -6px;
}

.selectedMove {
  background-color: #FFFFd8;
}

.selectedMove > td > span {
  font-weight: bold;
}

.selectedMove:hover {
  background-color: #FFFFb8 !important;
}

.duration {
  width: 3em;
}

.duration.isEdited {
  width: 6em;
}
</style>
