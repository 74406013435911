var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-space-between align-center"},[_c('v-col',[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.status.moveStartDate),expression:"status.moveStartDate"}],attrs:{"small":"","color":"success","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-clock ")]),_vm._v(" Simulation du "+_vm._s(_vm._f("startDateNow")(_vm.status.moveStartDate))+" à "+_vm._s(_vm._f("startTimeNow")(_vm.status.moveStartDate))+" ")],1)],1),_c('v-col',{staticClass:"justify-space-between text-right"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"orange lighten-1","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" fas fa-envelope")]),(_vm.message.length !== 0)?_c('span',[_vm._v("Modifier une annonce")]):_c('span',[_vm._v("Envoyer une annonce")])],1)]}}]),model:{value:(_vm.dialogMessage),callback:function ($$v) {_vm.dialogMessage=$$v},expression:"dialogMessage"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[(_vm.message.length !== 0)?_c('span',[_vm._v("Editer une annonce")]):_c('span',[_vm._v("Annonce à envoyer")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogMessage = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-textarea',{staticClass:"mt-8",attrs:{"name":"input-7-4","outlined":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","id":"trash-btn"},on:{"click":function($event){return _vm.sendMessage('')}}},[_vm._v(" Supprimer ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.sendMessage(_vm.message)}}},[_vm._v(" Envoyer ")])],1)],1)],1)],1)],1),_c('SimulatedDate',{attrs:{"actual-move":_vm.actualMove,"status":_vm.status}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.moves,"items-per-page":-1,"item-class":_vm.selectedMoveClass},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-12",class:item.selected === true ? 'indigo--text font-weight-bold selected-btn' : ''},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.durationInMinutes",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.durationInMinutes),expression:"item.durationInMinutes"}],staticClass:"duration",class:item.disabled === true ? '' : 'isEdited',attrs:{"disabled":item.disabled,"type":"number"},domProps:{"value":(item.durationInMinutes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "durationInMinutes", $event.target.value)}}})]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.disabled)?_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm._f("moveDate")(item.start))+" ")]):_vm._e(),(!item.disabled)?_c('v-row',{attrs:{"justify":"space-around","align":"center"}},[_c('v-col',[_c('h3',[_vm._v("jour")]),(!item.disabled)?_c('v-date-picker',{on:{"change":function($event){return _vm.updateItemDate(item,_vm.sdate)}},model:{value:(_vm.sdate),callback:function ($$v) {_vm.sdate=$$v},expression:"sdate"}}):_vm._e()],1)],1):_vm._e()]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [(item.disabled)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("moveStartToEnd")(item.start,item.end))+" ")]):_vm._e(),(!item.disabled)?_c('v-row',{attrs:{"justify":"space-around","align":"center"}},[_c('v-col',[_c('h3',[_vm._v("début")]),_c('v-time-picker',{attrs:{"format":"24hr","max":_vm.stimes[1]},on:{"click:hour":function($event){return _vm.updateItemTimes(item,_vm.stimes)},"click:minute":function($event){return _vm.updateItemTimes(item,_vm.stimes)}},model:{value:(_vm.stimes[0]),callback:function ($$v) {_vm.$set(_vm.stimes, 0, $$v)},expression:"stimes[0]"}})],1),_c('v-col',[_c('h3',[_vm._v("fin")]),_c('v-time-picker',{attrs:{"format":"24hr","min":_vm.stimes[0]},on:{"click:hour":function($event){return _vm.updateItemTimes(item,_vm.stimes)},"click:minute":function($event){return _vm.updateItemTimes(item,_vm.stimes)}},model:{value:(_vm.stimes[1]),callback:function ($$v) {_vm.$set(_vm.stimes, 1, $$v)},expression:"stimes[1]"}})],1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.action === false)?_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Amorcer","small":""},on:{"click":function($event){return _vm.nextMove(item)}}},[_vm._v(" fas fa-step-backward ")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Démarrer","small":""},on:{"click":function($event){return _vm.startMove(item)}}},[_vm._v(" fas fa-play ")])],1)],1):_c('div',[_c('v-btn',{staticClass:"stop-btn",attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Terminer le move","small":""},on:{"click":function($event){return _vm.endMove()}}},[_vm._v(" fas fa-fast-forward ")])],1),_c('v-btn',{attrs:{"icon":""}},[(_vm.status.moveStatus === "STARTED")?_c('v-icon',{attrs:{"small":"","color":"red accent-3"},on:{"click":function($event){return _vm.pauseMove(item)}}},[_vm._v(" fas fa-pause ")]):_c('v-icon',{attrs:{"title":"Démarrer","small":""},on:{"click":function($event){return _vm.startMove(item)}}},[_vm._v(" fas fa-play ")])],1)],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [((!item.selected || _vm.status.moveStatus === 'REALTIME') && item.disabled)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Modifier","disabled":item.selected && _vm.status.moveStatus !== "REALTIME"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!item.disabled)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Sauvegarder","color":"green accent-4"},on:{"click":function($event){return _vm.saveItem(item)}}},[_vm._v(" mdi-check ")]):_vm._e()]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"gutter":"8px"}},[_c('v-btn',{attrs:{"color":"error","title":"Arrêter le virtualday en cours / retour au temps réel"},on:{"click":_vm.resetVirtualDay}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-power-off ")]),_vm._v(" RAZ ")],1),(!_vm.isDefaultConfiguration)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"warning","title":"Supprimer les modifications et restaurer la liste de virtualdays par défaut"},on:{"click":_vm.resetToDefaultConfiguration}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-redo-alt ")]),_vm._v(" Config. par défaut ")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }